import { graphql } from 'gatsby';
import React from 'react';
import { Main } from '../components/Main';
import { Text } from '../components/typography/Text';
import Block from '../components/Block';

interface PrivacyPolicyProps {
    data: {
        datoCmsPrivacyPolicy: {
            policy: string;
        };
    };
}

export default function PrivacyPolicy({ data }: PrivacyPolicyProps): JSX.Element {
    return (
        <Block as={Main}>
            <Text dangerouslySetSanitizedHTML={data.datoCmsPrivacyPolicy.policy} />
        </Block>
    );
}

export const query = graphql`
    query PrivacyPolicyPage($language: String!) {
        datoCmsPrivacyPolicy(locale: { eq: $language }) {
            policy
        }
    }
`;
